$layout-breakpoint-sm: 576px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 992px;
$layout-breakpoint-xl: 1200px;
$layout-breakpoint-xxl: 1400px;
$layout-breakpoint-xxxl: 1900px;

.banner-header {
  font-family: 'Roboto';
  position: relative;
  width: 100%;

  img {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
    
  }

  .prev, .next {
    top: 45%;
    position: absolute;
    z-index: 1000;
    background: white;
    padding: 4px;
    border-radius: 12px;
    

    button {
      border-radius: 12px;
      background-color: rgb(157 157 157 / 50%);
    }
  }

  .prev {
    left: -15px;
  }

  .next {
    right: -15px;
  }

  .text-overlay {
    color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
    //padding: 40px 0;
    //@media (max-width: $layout-breakpoint-sm) {
    //  padding: 0;
    //}
    //// Medium devices (tablets, 768px and up)
    //@media (max-width: $layout-breakpoint-md) {
    //  padding: 0;
    //}

    .video-item {
      height: 100%;
      //flex: 0 0 300px;

      iframe {
        width: 100%;
        height: 100%;
        border: none
      }
    }
  }
}