$layout-breakpoint-sm: 576px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 992px;
$layout-breakpoint-xl: 1200px;
$layout-breakpoint-xxl: 1400px;
.site-stat {
  height: 150px;
  background: #7E7E7E;

  .stat {
    padding: 8px 4px;

    .stat-icon {
      padding: 18px;
      background-color: #000;
      width: 80px;
      height: 80px;
      border-radius: 50%;

      @media (max-width: $layout-breakpoint-sm) {
        padding: 12px;
        width: 48px;
        height: 48px;
      }
      // Medium devices (tablets, 768px and up)
      @media (max-width: $layout-breakpoint-md) {
        padding: 12px;
        width: 48px;
        height: 48px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .stat-number {
      text-align: left;
      font-size: 24px;
      color: #FFF;
      font-weight: 700;
      @media (max-width: $layout-breakpoint-sm) {
        text-align: center;
        font-size: 20px;
      }
      // Medium devices (tablets, 768px and up)
      @media (max-width: $layout-breakpoint-md) {
        text-align: center;
        font-size: 20px;
      }
    }

    .stat-label {
      text-align: left;

      color: #000;
      font-size: 20px;
      font-weight: 600;

      @media (max-width: $layout-breakpoint-sm) {
        text-align: center;
        font-size: 16px;
      }
      // Medium devices (tablets, 768px and up)
      @media (max-width: $layout-breakpoint-md) {
        text-align: center;
        font-size: 16px;
      }
    }
  }
}