$layout-breakpoint-sm: 576px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 992px;
$layout-breakpoint-xl: 1200px;
$layout-breakpoint-xxl: 1400px;
$layout-breakpoint-xxxl: 1900px;

.auction-widget {
  font-size: 16px;

  .auction-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DCDCDC;

    .title {
      font-size: 18px;
      font-style: normal;
      line-height: 30px;
      border-bottom: 3px solid #05452D;
    }

    .view-detail a {
      text-align: right;
      color: #222222;
      text-decoration: none;
    }
  }

}

.auction-items-widget {
  padding: 14px 0;
  display: flex;
  overflow-x: auto;

  .auction-item-widget {
    margin-right: 8px;
    flex: 0 0 315px;
    overflow: hidden;

    @media (min-width: $layout-breakpoint-lg) {
      flex: 0 0 450px;
    }
  }
}
