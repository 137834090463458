$layout-breakpoint-sm: 576px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 992px;
$layout-breakpoint-xl: 1200px;
$layout-breakpoint-xxl: 1400px;
footer {
  background-color: #000000;
  font-size: 16px;
  padding-top: 25px;
  padding-bottom: 25px;

  .app-logo {
    .logo {
      img {
        object-fit: cover;
        width: 100px;
        height: 100px;
        @media (max-width: $layout-breakpoint-sm) {
          width: 70px;
          height: 70px;
        }
        // Medium devices (tablets, 768px and up)
        @media (max-width: $layout-breakpoint-md) {
          width: 70px;
          height: 70px;
        }
      }
    }

    .title {
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 38.4px;
      @media (max-width: $layout-breakpoint-sm) {
        font-size: 24px;
      }
      // Medium devices (tablets, 768px and up)
      @media (max-width: $layout-breakpoint-md) {
        font-size: 24px;
      }
    }

    .slogan {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 14.4px;
    }
  }

  .footer-menu, .footer-social {
    list-style: none;
    padding-left: 0;
    a{
      text-decoration: none;
    }
  }

  .footer-social {
    display: flex;

    li {
      display: flex;
      align-items: center;

      a {
        padding: 10px;
        display: block;

        &.fb {
          img {
            object-fit: contain;
            width: 31px;
            height: 32px;
            @media (max-width: $layout-breakpoint-sm) {
              width: 22px;
              height: 32px;
            }
            // Medium devices (tablets, 768px and up)
            @media (max-width: $layout-breakpoint-md) {
              width: 22px;
              height: 32px;
            }
          }
        }

        &.yt {
          img {
            object-fit: contain;
            width: 33px;
            height: 22px;
            @media (max-width: $layout-breakpoint-sm) {
              width: 24px;
              height: 17px;
            }
            // Medium devices (tablets, 768px and up)
            @media (max-width: $layout-breakpoint-md) {
              width: 24px;
              height: 17px;
            }
          }
        }

        &.twit {
          padding: 0 15px;
          font-size: 28px;
          font-weight: bolder;
          color: #fcc83b;
          img {
            object-fit: contain;
            width: 41px;
            height: 41px;
            @media (max-width: $layout-breakpoint-sm) {
              width: 32px;
              height: 32px;
            }
            // Medium devices (tablets, 768px and up)
            @media (max-width: $layout-breakpoint-md) {
              width: 32px;
              height: 32px;
            }
          }
        }

      }
    }
  }
}