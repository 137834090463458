$layout-breakpoint-sm: 576px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 992px;
$layout-breakpoint-xl: 1200px;
$layout-breakpoint-xxl: 1400px;
$layout-breakpoint-xxxl: 1900px;


html, body {
  overflow: hidden;
}

html, body, #root {
  width: 100%;
  height: 100%;
}


#root {
  overflow-x: hidden;
  background-image: url("../assets/image/site-wallpaper.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.layout-padding {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: $layout-breakpoint-sm) {
  .layout-padding {
    padding-left: 20px;
    padding-right: 20px;
  }

}

// Medium devices (tablets, 768px and up)
@media (min-width: $layout-breakpoint-md) {
  .layout-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $layout-breakpoint-lg) {
  .layout-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $layout-breakpoint-xl) {
  .layout-padding {
    padding-left: 40px;
    padding-right: 40px;
  }
}

// xx  large devices (large desktops, 1400px and up)
@media (min-width: $layout-breakpoint-xxl) {
  .layout-padding {
    padding-left: 60px;
    padding-right: 60px;
  }
}

// xxx  large devices (large desktops, 1900px and up)
@media (min-width: $layout-breakpoint-xxxl) {
  .layout-padding {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.z-support-btn {
  border-radius: 50%;
  bottom: 95px;
  height: 60px;
  overflow: hidden;
  position: fixed;
  right: 23px;
  width: 60px;
  z-index: 1030;
}


.text-orange {
  color: #FCC83B;
}

.text-green {
  color: #05452D;
}

.text-red {
  color: #E10000;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.btn.btn-third {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  background: rgb(253, 137, 0);
  background: linear-gradient(90deg, rgba(253, 137, 0, 1) 0%, rgba(250, 187, 81, 1) 100%);
  padding: 8px 12px 8px 12px;
  border-radius: 20px;
}


.pagination {
  display: inline-block;
  padding-left: 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination-sm > li:first-child > a, .pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  margin-right: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination-sm > li > a, .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.pagination > li > a, .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #2c2b2b;
  border-color: #2c2b2b;
}

.pagination-sm > li > a, .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 16px;
  line-height: 1.5;
}

.pagination > li > a, .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #666;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #D9E1E4;
}

.menu-mobile-header {
  color: #FFFFFF;
  background: #05452D;

  button.btn-close {
    color: #FFFFFF;
  }

}

.menu-mobile-content {
  list-style: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  padding-left: 0;

  li {
    border-bottom: 1px solid #dbdbdb;

    a {
      display: block;
      text-decoration: none;
      color: #222;
      padding: 10px 20px 15px 0px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.tbl-history {
  thead tr th {
    background-color: #E7E1CD;
    color: #282828;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    border-right: 1px solid #FFFFFF;

    &:last-child {
      border-right: inherit;
    }
  }

  tbody tr td {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #666;
  }

}

.right-banner {
  display: flex;
  flex-flow: column;
  gap: 20px;
  width: 270px;
  position: absolute;
  right: 10px;
  top: 30px;
  z-index: 100;
  justify-content: center;
  align-items: center;

  .right-banner-item {
    height: 160px;

    iframe {
      width: 100%;
      height: 100%;
      border: none
    }
  }
}

.left-banner {
  display: flex;
  width: 270px;
  position: absolute;
  left: 10px;
  top: 30px;
  z-index: 100;
  justify-content: center;
  align-items: center;
  flex-flow: column;

}

//.popup-content {
//  margin-left: 0;
//  @media (min-width: $layout-breakpoint-xl) {
//    margin-left: 285px
//  }
//}

