.product-image{
    width: 100%;
    padding-top: 5px;
}
.btn.btn-buy-acc{
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    margin-left: 15px;
    background-image: linear-gradient(to right,#F8A433, #FEE140);
    border-radius: 25px;
    padding: 5px 40px;
}