$header-border-color: #D8D8D8;
$layout-breakpoint-sm: 576px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 992px;
$layout-breakpoint-xl: 1200px;
$layout-breakpoint-xxl: 1400px;
.app-header {
  font-family: 'Roboto';
  font-size: 16px;
  //padding: 0px 15px;

  .run-text {
    height: 57px;
    background-color: #05452D;
    display: flex;
    align-items: center;

    marquee {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #FFFFFF;
    }

  }

  .logo-menu {

    border-bottom: 1px solid $header-border-color;
    padding-top: 12px;
    padding-bottom: 12px;

    .btn-menu-mobile {
      padding: 6px 8px;
      position: relative;
      top: 1px;
      color: #000000;

      &:focus {
        box-shadow: none;
      }
    }


    .logo {
      img {
        width: 30px;
        height: 30px;
        object-fit: cover;

        @media (min-width: $layout-breakpoint-sm) {
          width: 50px;
          height: 50px;
        }
        // Medium devices (tablets, 768px and up)
        @media (min-width: $layout-breakpoint-md) {
          width: 50px;
          height: 50px;
        }
      }
    }

    .app-title {
      h5 {
        font-size: 10px;
      }

      h6 {
        font-size: 10px;
        max-width: 110px;
        white-space: normal;
      }

      @media (min-width: $layout-breakpoint-sm) {
        h5 {
          font-size: 16px;
          max-width: none;
        }
        h6 {
          font-size: 12px;
          max-width: none;
        }
      }
      // Medium devices (tablets, 768px and up)
      @media (min-width: $layout-breakpoint-md) {
        h5 {
          font-size: 16px;
        }
        h6 {
          font-size: 12px;
        }
      }
    }
  }

  .login-menu {
    .login-text {
      color: #666666;
      text-decoration: none;
      @media (max-width: $layout-breakpoint-md) {
        font-size: 12px;
      }
      @media (max-width: $layout-breakpoint-sm) {
        font-size: 12px;
      }

      img {
        width: 24px;
        height: 24px;
        position: relative;
        top: -2px;
        @media (max-width: $layout-breakpoint-md) {
          width: 18px;
          height: 18px;
        }
        @media (max-width: $layout-breakpoint-sm) {
          width: 18px;
          height: 18px;
        }
      }

      &::after {
        display: none;
      }
    }

    .login-success {
      font-size: 16px;

      li {
        &.user-info {
          p {
            cursor: default;
            margin-bottom: 0;

            &:hover {
              background-color: transparent;
            }

            &:active {
              background-color: transparent;
              color: inherit;
            }
          }
        }

        a {
          cursor: pointer;

          &:active {
            background-color: #f8f9fa;
          }
        }
      }

    }
  }


  .app-navigator {
    border-bottom: 1px solid $header-border-color;
    padding-top: 16px;
    padding-bottom: 16px;

    .nav-list {
      list-style: none;
      margin-bottom: 0;
      display: flex;
      padding-left: 0;

      li {
        margin-right: 19px;

        a.nav-link {
          padding: 9px 14px;
          border-radius: 16px;
          background-color: #EFEFEF;
          color: #222222;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          cursor: pointer;

          &.active {
            background-color: #FAB036;
            color: #FFFFFF;
          }
        }
      }
    }
  }

}