.login-wrapper {
  font-family: 'Roboto';
  font-style: normal;
  background: #05452D;
  width: 100%;
  height: 100%;
  font-size: 16px;


  .login-img {
    padding: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text-overlay {
      color: #FFFFFF;
      position: absolute;
      top: 58px;
      left: 43px;

      .t1 {
        font-size: 28px;
        font-weight: 400;
      }

      .t2 {
        font-size: 34px;
        font-weight: 700;

      }

      .t3 {
        font-size: 28px;
        font-weight: 400;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .login-panel {
    padding: 74px 90px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(245, 242, 234, 1) 79%, rgba(249, 246, 238, 1) 98%);
    overflow-y: auto;
    height: 100%;

    .login-form {
      .login-form-header {
        h1 {

          color: #222224;
          font-size: 40px;
          font-weight: 600;
          line-height: 48px;
        }

        p {
          color: #646464;
          font-size: 20px;
          font-weight: 400;

          a {
            color: #646464;
            font-weight: 700;
          }
        }
      }

      form {
        margin-top: 40px;

        .form-group {
          margin-bottom: 20px;
          position: relative;

          .form-control {
            border-radius: 30px;
            padding: 12px 18px;

            &.has-icon {
              padding-right: 40px;
            }
          }

          .form-control-icon {
            position: absolute;
            right: 16px;
            top: 12px;
            width: 24px;
            height: 24px;
          }

          .form-link {
            color: #696984;
            font-weight: 400;
            text-decoration: none;
          }

          button[type=submit] {
            border-radius: 24px;
            background: #FAB036;
            padding: 16px 24px;
            color: #FFF;
            font-weight: 700;
            border: none
          }
        }

        .help {
          margin-top: 42px;
          text-align: center;

          p {
            color: #646464;
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 0;

            a {
              color: #FBB445;
              font-size: 20px;
              font-weight: 600;
              text-decoration: none;
            }
          }
        }

        .form-text {
          margin-left: 10px;
          margin-top: 6px;
        }
      }
    }
  }

}