
.back-lucky {
  .table thead th {
    border-bottom: 2px solid #A5651C;
  }

  .table-bordered td, .table-bordered th {
    border: 1px solid #A5651C !important;
  }

  .wheel {
    text-align: center;
    padding-top: 50px;

    canvas {
      position: relative;
      left: 0;
    }

    a#spin_start {
      position: relative;

      img {
        width: 90px;
        top: -310px;
        left: -347px;
        position: relative;
        transition: none 0s ease 0s;
        cursor: pointer;
        transform: rotate(3deg);
      }
    }
  }

}