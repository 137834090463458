$layout-breakpoint-sm: 576px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 992px;
$layout-breakpoint-xl: 1200px;
$layout-breakpoint-xxl: 1320px;
.auction-item {
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid #BEBEBE;
  overflow: hidden;


  .acc-avatar {
    position: relative;
    background-image: url('../../assets/image/acc-item-container.png');
    background-position: -38px -14px;
    background-repeat: no-repeat;
    background-size: 163%;
    //width: 125px;
    //height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 0 0 125px;
    @media (min-width: $layout-breakpoint-lg) {
      flex: 0 0 180px;
      background-position: -46px -10px;
      background-size: 152%;
      //width: 125px;
    }

    img {
      width: 88px;
      height: 76px;
      margin-top: 28px;
      @media (min-width: $layout-breakpoint-lg) {
        width: 161px;
        height: 161px;
      }

    }
  }

  .acc-info {
    flex: 1;
    padding: 10px 14px;
    display: flex;
    flex-flow: column;
    width: 100%;
    overflow: hidden;

    .acc-name {
      overflow: hidden;
      color: #181818;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      margin-bottom: 4px;
      @media (min-width: $layout-breakpoint-lg) {
        font-size: 20px;
      }
    }

    .info {
      font-size: 12px;
      color: #666666;
      margin-bottom: 4px;
      @media (min-width: $layout-breakpoint-lg) {
        font-size: 14px;
      }

    }

    .acc-price {
      display: flex;
      flex-flow: column;

      .label {
        font-size: 12px;
        color: #000000;
        @media (min-width: $layout-breakpoint-lg) {
          font-size: 14px;
        }
      }

      .price {
        color: #FF1414;
        font-size: 16px;
        font-weight: 700;
        @media (min-width: $layout-breakpoint-lg) {
          font-size: 24px;
        }
      }

    }

    .btn.btn-place-bid {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 600;
      background: rgb(253, 137, 0);
      background: linear-gradient(90deg, rgba(253, 137, 0, 1) 0%, rgba(250, 187, 81, 1) 100%);
      padding: 8px 12px 8px 12px;
      border-radius: 20px;
      width: 128px;
      height: 36px;
      line-height: normal;
      @media (min-width: $layout-breakpoint-lg) {
        font-size: 20px;
        width: 155px;
        height: 40px;
      }
    }
  }

  .auction-deadline {
    background-color: #D9D9D9;
    padding: 8px 18px;
    display: flex;
    justify-content: flex-end;

    .timer {
      color: #FF1414;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-right: 25px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}