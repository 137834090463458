$layout-breakpoint-sm: 576px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 992px;
$layout-breakpoint-xl: 1200px;
$layout-breakpoint-xxl: 1400px;
$layout-breakpoint-xxxl: 1900px;

.auction-page {
  font-family: 'Roboto';
  font-style: normal;
  padding-top: 16px;
  padding-bottom: 16px;


  .auction-items {
    //padding: 14px 0;
    display: flex;
    overflow-x: auto;

    .auction-item-list {
      margin-right: 8px;
      flex: 0 0 315px;
      overflow: hidden;
      @media (min-width: $layout-breakpoint-lg) {
        flex: 0 0 450px;
      }
    }
  }

}

.auction-detail-modal {
  .modal-content {
    background-color: #ffffff;
    color: #181818;
  }

  .close {
    color: #181818;
  }

  .auction-detail-container {
    max-height: 800px;

    .auction-detail__account-info {
      display: flex;
      flex-flow: column;
      align-items: center;
      max-height: 100%;
      overflow-y: auto;

      .auction-detail-images {
        display: flex;
        flex-flow: column;

        .__img {
          max-width: 100%;
          max-height: 128px;

          img {
            height: 100%;
            width: auto;
            max-width: 100%;
          }
        }
      }
    }

    .auction-bid {
      display: flex;
      flex-flow: column;
      padding: 10px 25px;
      background-color: #fbfbfb;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

      .bid-pricing {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price-label, .price-value {
          font-size: 16px;
          font-weight: 500;
        }

        &.current-price {
          .price-value {
            font-size: 24px;
          }
        }

        .price-label {
          color: #353535;
        }
      }

      .my-bid {
        span {
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
  }
}
