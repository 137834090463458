$layout-breakpoint-sm: 576px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 992px;
$layout-breakpoint-xl: 1200px;
$layout-breakpoint-xxl: 1400px;
.account-list-container {
  //padding: 14px 0;

  .account-category {
    .ac-nav {
      list-style: none;
      padding-left: 0;
      display: flex;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;
      border-bottom: 1px solid #DCDCDC;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        display: block;
        @media (max-width: $layout-breakpoint-sm) {
          flex: 1
        }
        // Medium devices (tablets, 768px and up)
        @media (max-width: $layout-breakpoint-md) {
          flex: 1
        }

        a {
          display: block;
          padding: 4px 12px;
          white-space: nowrap;
          font-weight: 700;
          color: #767676;
          width: 100%;
          text-align: center;
          text-decoration: none;
          cursor: pointer;


          &.active {
            color: #05452D;
            border-bottom: 3px solid #05452D;
          }

        }

      }

    }
  }

  .search-bar {
    .input-group {
      border-radius: 10px;
      overflow: hidden;
      width: 100%;

      @media (max-width: $layout-breakpoint-sm) {
        width: 75%;
      }
      // Medium devices (tablets, 768px and up)
      @media (max-width: $layout-breakpoint-md) {
        width: 75%;
      }

      input, select {
        border-radius: 10px;
        background-color: #EFEFEF;
        border: none;

        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }
      }

      .input-group-append {
        background-color: #EFEFEF;

        .btn.btn-search {
          border-radius: 10px;
          background: #FAB036;

          img {
            position: relative;
            bottom: 2px;
          }
        }
      }

    }
  }

  .acc-list {
    .acc-item {
      margin-top: 12px;

      @media (max-width: $layout-breakpoint-md) {
        padding-right: 8px;
        padding-left: 8px;
      }
      // Large devices (desktops, 992px and up)
      @media (max-width: $layout-breakpoint-sm) {
        padding-right: 8px;
        padding-left: 8px;
      }


      .overlay {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        background-image: url('../../assets/image/acc-item-container.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 100%;
        display: flex;
        flex-flow: column;
        border: 1px solid #BEBEBE;
        box-shadow: 4px 8px 12px 0px rgba(0, 0, 0, 0.25);


        //@media (min-width: $layout-breakpoint-sm) {
        //  background-position: -17px -8px;
        //  background-size: 434px 325px;
        //}
        //
        //// Medium devices (tablets, 768px and up)
        //@media (min-width: $layout-breakpoint-md) {
        //  background-position: -21px -7px;
        //  background-size: 398px 353px;
        //}
        //
        //// Large devices (desktops, 992px and up)
        //@media (min-width: $layout-breakpoint-lg) {
        //  background-position: -27px -10px;
        //  background-size: 494px 366px;
        //}
        //
        //// Extra large devices (large desktops, 1200px and up)
        //@media (min-width: $layout-breakpoint-xl) {
        //  background-position: -26px -10px;
        //  background-size: 308px 361px;
        //}
        //
        //// xx  large devices (large desktops, 1200px and up)
        //@media (min-width: $layout-breakpoint-xxl) {
        //  background-position: -17px -8px;
        //  background-size: 434px 325px;
        //}

        .acc-code {
          position: absolute;
          top: 0;
          right: 0;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;
          border-radius: 0 16px;
          background: #181818;
          color: #FFFFFF;
          padding: 4px 13px;
          @media (min-width: $layout-breakpoint-lg) {
            font-size: 20px;
            padding: 6px 20px;
          }

        }

        .acc-avatar {
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;


          .acc-info-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #0000008a;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 18px;
            padding: 12px;
          }

          &:hover .acc-info-overlay {
            display: flex;
          }

          @media (min-width: $layout-breakpoint-lg) {
            height: 298px;
          }

          img {
            width: 115px;
            height: 99px;
            @media (min-width: $layout-breakpoint-lg) {
              width: 280px;
              height: 280px;
            }
          }

          iframe {
            width: 100%;
            height: 100%;
            border: none
          }
        }

        .acc-info {
          background-color: #FFFFFF;
          border-radius: 10px;
          overflow: hidden;

          .acc-name {
            padding: 8px;
            background: #D9D9D9;
            font-size: 16px;
            font-weight: 700;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (min-width: $layout-breakpoint-lg) {
              font-size: 18px;
              padding: 15px 20px;
            }

            .show-video-player {
              cursor: pointer;
              font-size: 18px;
              padding: 0px 4px;
            }
          }

          .acc-detail {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            align-content: stretch;
            gap: 4px;

            .info {
              font-size: 12px;
              font-weight: 700;
              flex: 1 1 auto;
              @media (min-width: $layout-breakpoint-lg) {
                font-size: 16px;
              }
            }
          }

          .acc-price {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .payment-method {
              color: #f92020;
              font-family: 'Montserrat';
              font-size: 14px;
              font-weight: 700;
              flex: 0 1 35px;
              margin-top: 2px;
            }

            .price {
              flex: 1;
              padding: 0 4px;
              white-space: nowrap;

              .base-price {
                color: #f92020;
                font-weight: 700;
                text-align: left;
              }

              .sale-price {
                color: #f92020;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;

              }

            }

            .acc-action {
              text-align: center;
              margin: 0 auto;

              .btn.btn-third.btn-purchase {
                padding: 6px 16px 6px 16px;
                font-size: 16px;
                background: #FAB036;
                border-radius: 30px;
                font-weight: 600;
                @media (min-width: $layout-breakpoint-lg) {
                  font-size: 18px;
                  padding: 6px 24px;
                }
              }
            }


          }

        }

      }
    }
  }
}