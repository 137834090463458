.toprecharge {
  color: #ffffff;
}
.toprecharge__title {
  padding-top: 120px;
}
.toprecharge__title h3 {
  color: #ffffff;
  text-align: center;
}
.toprecharge-list {
  min-height: 40vh;
}
.toprecharge-list .card-deck {
  align-items: flex-end;
}
.toprecharge-list .card {
  background-color: #1f1a38;
}
.toprecharge-list .card.top-1 {
  height: 350px;
}
.toprecharge-list ul.charger-info li {
  margin-bottom: 16px;
}
.toprecharge-list ul.charger-info .number {
  font-weight: 500;
}
.toprecharge-table .table-dark {
  background-color: rgba(31, 26, 56, 0.8588235294);
}
.toprecharge .top-charger {
  cursor: pointer;
}

.charger-info-modal .modal-content {
  background-color: #1f1a38;
  color: #fff;
}
.charger-info-modal .close {
  color: #fff;
}
.charger-info-modal .modal-body {
  padding-top: 4px;
}
.charger-info-modal .basic-stat {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.charger-info-modal .basic-stat .name {
  padding-right: 4px;
  margin-bottom: 12px;
}
.charger-info-modal .basic-stat .info-line {
  font-size: 18px;
}
.charger-info-modal .basic-stat .info-line .number {
  font-weight: 500;
}
.charger-info-modal .table-charger-timeline tr td {
  text-align: center;
}


