.toprecharge {
  $container-color: #ffffff;
  color: $container-color;

  &__title {
    padding-top: 120px;

    h3 {
      color: $container-color;
      text-align: center;
    }
  }

  &-list {
    min-height: 40vh;

    .card-deck {
      align-items: flex-end;
    }

    .card {
      background-color: #1f1a38;

      &.top-1 {
        height: 350px;
      }
    }

    ul.charger-info {
      li {
        margin-bottom: 16px;
      }

      .number {
        font-weight: 500;
      }
    }
  }

  &-table {
    .table-dark {
      background-color: #1f1a38db;
    }
  }

  .top-charger {
    cursor: pointer;
  }

}

.charger-info-modal {
  //position: relative;
  .modal-content {
    background-color: #1f1a38;
    color: #fff
  }

  .close {
    color: #fff;
    
  }
  .modal-body{
    padding-top: 4px;
  }

  .basic-stat {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .name {
      //margin-top: 4px;
      padding-right: 4px;
      margin-bottom: 12px;
    }

    .info-line {
      font-size: 18px;

      .number {
        font-weight: 500;
      }
    }
  }

  .table-charger-timeline {
    tr {
      td {
        text-align: center;
      }
    }
  }
}