
.user-page {
  font-family: 'Roboto';
  font-style: normal;
  padding-top: 16px;
  padding-bottom: 16px;

}

.account-info-popover {
  font-family: 'Roboto';
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.30);
  border: none;

  .popover-header {
    background-color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    padding: 14px 86px 14px 18px;
    white-space: nowrap;
    border-bottom: 2px solid #E7E1CD;
  }

  .popover-body {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }
}